import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Xiaoyun Zhang</h2>
        <p>
          <a href="mailto:cloudszhang0727@outlook.com">cloudszhang0727@outlook.com</a>
        </p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>
        Hi, I&apos;m Xiaoyun. I am a{' '} Technical Artist focusing on game development
        pipeline-related tool building. I have used Unity for game development for almost 9 years
        and have made a few games by myself or with teams.
        I&apos;m always open to other game engines and techniques.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? (
            <Link to="/resume" className="button">
              Learn More
            </Link>
          ) : (
            <Link to="/about" className="button">
              About Me
            </Link>
          )}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">
        {/* &copy; Michael D&apos;Angelo <Link to="/">mldangelo.com</Link>. */}
        This website is made by Xiaoyun based on <Link to="https://github.com/mldangelo/personal-site">Michael D&apos;Angelo</Link> &apos;s template.
      </p>
    </section>
  </section>
);

export default SideBar;
